import React, { Fragment } from 'react';
import Contact from '@components/Contact';
import { graphql } from 'gatsby';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Section from '@components/theme/Section';
import { getFirstBlock } from '@lib/blocks';

export default function ContactUs({ data: { contactBlock }, pageContext: { language, refs, layouts, configuration }}) {
  const contact = getFirstBlock(contactBlock);
  return (
    <Fragment>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <Section className="bg-primary">
        <Contact
          data={contact}
          language={language}
          typeforms={configuration.typeforms}
        />
      </Section>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Fragment>
  );
}

export const query = graphql`
  query($contactId: String) {
    contactBlock: wpCustomPage(id: { eq: $contactId }) {
      ...ContactBlock
    }
  }
`;